<template>
    <div class="row ld-over" :class="[{'running': dataLoading}]">

        <div class="col">
            <div class="mb-2 mt-2 fl-te-c">
                <div v-show="showSearchBox">
                    <validated-input placeholder="Search" v-model="search"></validated-input>
                    <!--                    <input type="text" class="form-control form-control-sm" placeholder="Search" v-model="search"/>-->
                </div>

                <div class="">
                    <span class="text-muted text-small mr-1 mb-2">{{ from }}-{{ to }} of {{ total }}</span>
                </div>
            </div>

<!--            <div class="ld ld-ring ld-spin" v-if="dataLoading">Loading</div>-->

            <div class="loading" v-if="dataLoading">
                <slot name="error">
                    <div class="card">
                        <loading-animation/>
                        Loading Data...
                    </div>
                </slot>
            </div>

            <div class="loading" v-show="error">
                <slot name="error">
                    <div class="card bg-danger">
                        <error-icon/>
                        Loading Data Failed.
                    </div>
                </slot>
            </div>

            <vuetable ref="vuetable" class="order-with-arrow" pagination-path :api-url="url" :per-page="perPageValue"
                      :reactive-api-url="true" :fields="fields" @vuetable:pagination-data="onPaginationData"
                      @vuetable:loading="showLoadingAnimation"
                      @vuetable:load-success="hideLoadingAnimation" @vuetable:load-error="setError"
                      :query-params="makeQueryParams" v-show="!error && !dataLoading" :load-on-start="loadOnStart">
                <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
                    <slot :name="slot" v-bind="scope"/>
                </template>
            </vuetable>

            <vuetable-pagination-bootstrap :next-icon="nextIcon" :prev-icon="prevIcon" v-show="showPagination"
                                           class="mt-4 fl-eqh-c" ref="pagination"
                                           @vuetable-pagination:change-page="onChangePage"/>
        </div>
    </div>
</template>

<script>
import VueTableComponentMixin from 'lego-framework/src/tables/VueTableComponentMixin';

export default {
    name    : 'vue-table',
    extends : VueTableComponentMixin
};
</script>
