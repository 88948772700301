const prod = process.env.NODE_ENV === 'production';

let baseUrl;
if (prod) {
    baseUrl = '/';
} else {
    baseUrl = '/';
}

const subBaseUrl = 'esaf-api/';

export default {
    baseUrl,

    auth : {
        login  : baseUrl + subBaseUrl + 'auth/login/',
        status : baseUrl + subBaseUrl + 'auth/status/',
        logout : baseUrl + subBaseUrl + 'auth/logout/'
    },

    ourWork : {
        addEdit : baseUrl + subBaseUrl + 'our-works/add-edit/',
        delete  : baseUrl + subBaseUrl + 'our-works/delete/',
        list    : baseUrl + subBaseUrl + 'our-works/list/'
    },

    impactStories : {
        addEdit : baseUrl + subBaseUrl + 'impact-stories/add-edit/',
        delete  : baseUrl + subBaseUrl + 'impact-stories/delete/',
        list    : baseUrl + subBaseUrl + 'impact-stories/list/',
        links   : {
            addEdit : baseUrl + subBaseUrl + 'impact-stories/links/add-edit/',
            delete  : baseUrl + subBaseUrl + 'impact-stories/links/delete/',
            list    : baseUrl + subBaseUrl + 'impact-stories/links/list/'
        }
    },

    volunteer : {
        list : baseUrl + subBaseUrl + 'volunteer/list/'
    },

    workWithUs : {
        list : baseUrl + subBaseUrl + 'work-with-us/list/'
    },

    partnerWithUs : {
        list : baseUrl + subBaseUrl + 'partner-with-us/list/'
    },

    project : {
        addEdit : baseUrl + subBaseUrl + 'project/add-edit/',
        delete  : baseUrl + subBaseUrl + 'project/delete/',
        list    : baseUrl + subBaseUrl + 'project/list/'
    },

    contactUs : {
        list : baseUrl + subBaseUrl + 'contact-us/list/'
    },

    vacancy : {
        addEdit : baseUrl + subBaseUrl + 'vacancy/add-edit/',
        delete  : baseUrl + subBaseUrl + 'vacancy/delete/',
        list    : baseUrl + subBaseUrl + 'vacancy/list/'
    },

    programs : {
        program : {
            addEdit : baseUrl + subBaseUrl + 'program/add-edit/',
            delete  : baseUrl + subBaseUrl + 'program/delete/',
            list    : baseUrl + subBaseUrl + 'program/list/'
        },

        focusArea : {
            addEdit : baseUrl + subBaseUrl + 'program/focus-area/add-edit/',
            delete  : baseUrl + subBaseUrl + 'program/focus-area/delete/',
            list    : baseUrl + subBaseUrl + 'program/focus-area/list/'
        },

        banner : {
            addEdit : baseUrl + subBaseUrl + 'program/banner/add-edit/',
            delete  : baseUrl + subBaseUrl + 'program/banner/delete/',
            list    : baseUrl + subBaseUrl + 'program/banner/list/'
        }
    }
};
