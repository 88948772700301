import guards from './guards';
const Layout = () => import('../layouts/MainLayout');
const Home = () => import('../views/Home');

export default {
    path        : '/',
    name        : 'DashboardLayout',
    redirect    : '/',
    component   : Layout,
    beforeEnter : guards.loggedInGuard,
    children    : [
        {
            path      : '/',
            name      : 'Home',
            component : Home
        },
        {
            path      : '/our-work/',
            name      : 'our-work',
            component : () => import('../views/admin/our-work/OurWork')
        },
        {
            path      : '/impact-stories/',
            name      : 'impact-stories',
            component : () => import('../views/admin/impact-stories/ImpactStories')
        },
        {
            path      : '/impact-stories/:id/:name/link/',
            name      : 'impact-stories-link',
            component : () => import('../views/admin/impact-stories/links/Link')
        },
        {
            path      : '/volunteer-with-us/',
            name      : 'volunteer-with-us',
            component : () => import('../views/admin/volunteer/VolunteerWithUs')
        },
        {
            path      : '/work-with-us/',
            name      : 'work-with-us',
            component : () => import('../views/admin/work-with-us/WorkWithUs')
        },
        {
            path      : '/partner-with-us/',
            name      : 'partner-with-us',
            component : () => import('../views/admin/partner-with-us/PartnerWithUs')
        },
        {
            path      : '/project/',
            name      : 'project',
            component : () => import('../views/admin/project/Project')
        },
        {
            path      : '/contact-us/',
            name      : 'contact-us',
            component : () => import('../views/admin/contact-us/ContactUs')
        },
        {
            path      : '/vacancy/',
            name      : 'vacancy',
            component : () => import('../views/admin/vacancy/Vacancy')
        },
        {
            path      : '/programs/',
            name      : 'programs',
            component : () => import('../views/admin/programs/Program')
        },
        {
            path      : '/programs/:id/details/',
            name      : 'programs-details',
            component : () => import('../views/admin/programs/Details')
        }
    ]
};
